import { Button, Col, Row, Spin } from "antd";
import React, { Component } from "react";
import { addTask, getTaskById, getWorkerById, reviveOrder } from "../util/APIUtils";
import { mapOrganizationToTDP } from "../util/Helpers";
import CompleteTask from "./CompleteTask";
import DestinationInfoContent from "./DestinationInfoContent";
import GoboltTimelineInfoContent from "./GoboltTimelineInfoContent";
import PackagesDetailsModal from "./PackagesDetailsModal";
import RecipientInfoContent from "./RecipientInfoContent";
import ScanInfoContent from "./ScanInfoContent";
import TimeChangeEventInfoContent from "./TimeChangeEventInfoContent";

export class OrderListDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: this.props.order,
      tasksDetails: [],
      detailsLoaded: false,
      isViewPackageDetailsVisible: false,
    };
  }

  async componentDidMount() {
    let order = this.props.order;

    if (order.tasks != null && this.state.tasksDetails.length == 0) {
      let tasksDetails = [];
      for (let task of order.tasks) {
        let getTaskPromise = getTaskById(task.id);
        try {
          let onfleetTask = await getTaskPromise;
          onfleetTask.executorName = mapOrganizationToTDP(onfleetTask.executor);
          if (onfleetTask.worker != null) {
            let getWorkerPromise = getWorkerById(onfleetTask.worker);
            try {
              let worker = await getWorkerPromise;
              onfleetTask.workerDetails = worker;
            } catch (err) {}
          }
          tasksDetails.push(onfleetTask);
        } catch (err) {}
        this.setState({
          detailsLoaded: true,
          tasksDetails: tasksDetails,
        });
      }
      this.setState({
        detailsLoaded: true,
        //tasksDetails: tasksDetails
      });
    }
  }

  toggleViewPackageDetails = (visible) => {
    this.setState({ isViewPackageDetailsVisible: visible });
  };

  render() {
    if (!this.state.detailsLoaded) {
      return (
        <Row>
          <Col
            sm={{ span: 16, offset: 4 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
          >
            <Row type={"flex"} justify={"center"} align={"middle"}>
              <Spin />
            </Row>
          </Col>
        </Row>
      );
    }
    return (
      <div style={{ background: "#ECECEC", padding: "3vmin" }}>
        <Row gutter={16}>
          <Col span={10}>
            {this.state.order.rateChoice !== undefined ? (
              <RecipientInfoContent
                recipient={this.state.order.recipient}
                notes={this.state.order.notes}
                packageCount={this.state.order.packageCount}
                orderId={this.state.order.id}
                completeAfter={this.state.order.completeAfter}
                completeBefore={this.state.order.completeBefore}
                rateChoice={this.state.order.rateChoice}
                deliveryType={this.state.order.deliveryType}
                orderStatus={this.state.order.orderStatus}
                orderItems={this.state.order.orderItems}
                source={this.state.order.source}
                originalCompleteAfter={this.state.order.originalCompleteAfter}
                originalCompleteBefore={this.state.order.originalCompleteBefore}
                customFields={this.state.order.customFields}
                refreshOrdersFromEdit={this.props.refreshOrdersFromEdit}
                minimumAge={this.state.order.minimumAge}
                dispatcher={this.state.order.dispatcher}
                goboltOrderCreate={this.state.order.goboltOrderCreate}
                toggleViewPackageDetails={this.toggleViewPackageDetails}
              />
            ) : (
              <RecipientInfoContent
                recipient={this.state.order.recipient}
                notes={this.state.order.notes}
                packageCount={this.state.order.packageCount}
                orderId={this.state.order.id}
                completeAfter={this.state.order.completeAfter}
                completeBefore={this.state.order.completeBefore}
                orderStatus={this.state.order.orderStatus}
                orderItems={this.state.order.orderItems}
                source={this.state.order.source}
                deliveryType={this.state.order.deliveryType}
                originalCompleteAfter={this.state.order.originalCompleteAfter}
                originalCompleteBefore={this.state.order.originalCompleteBefore}
                customFields={this.state.order.customFields}
                refreshOrdersFromEdit={this.props.refreshOrdersFromEdit}
                minimumAge={this.state.order.minimumAge}
                dispatcher={this.state.order.dispatcher}
                goboltOrderCreate={this.state.order.goboltOrderCreate}
                toggleViewPackageDetails={this.toggleViewPackageDetails}
              />
            )}
          </Col>
          <Col span={9}>
            <DestinationInfoContent
              dtoRecipientAddress={this.state.order.dtoRecipientAddress}
              recipientAddress={this.state.order.recipientAddress}
              originAddress={this.state.order.originAddress}
              orderId={this.state.order.id}
              refreshOrdersFromEdit={this.props.refreshOrdersFromEdit}
            />
            <Row>
              {["ARCHIVED", "CREATED", "GEOCODEDFAILED"].includes(this.state.order.orderStatus) ? (
                <Col span={9}>
                  <Button
                    type="primary"
                    style={{ margin: "auto" }}
                    onClick={async () => {
                      this.setState({ detailsLoaded: false });
                      await reviveOrder(this.state.order.id);
                      setTimeout(() => {
                        this.props.refreshOrders();
                        this.setState({ detailsLoaded: true });
                      }, 5000);
                    }}
                  >
                    Retry Geocode
                  </Button>
                </Col>
              ) : (
                ""
              )}

              {this.state.order.orderStatus == "GEOCODED" ||
              this.state.order.orderStatus == "PICKUP_READY" ||
              this.state.order.orderStatus == "CARRIER_PICKED_UP" ? (
                <Col span={9}>
                  <Button
                    type="primary"
                    style={{ margin: "auto" }}
                    onClick={async () => {
                      this.setState({ detailsLoaded: false });
                      await addTask(this.state.order.id, {
                        orderId: this.state.order.id,
                      });
                      setTimeout(() => {
                        this.props.refreshOrders();
                        this.setState({ detailsLoaded: true });
                      }, 1000);
                    }}
                  >
                    Add New Task
                  </Button>
                </Col>
              ) : (
                ""
              )}

              {this.state.order.orderStatus == "GEOCODED" ||
              this.state.order.orderStatus == "PICKUP_READY" ||
              this.state.order.orderStatus == "DELIVERY_FAILED" ||
              this.state.order.orderStatus == "CARRIER_PICKED_UP" ||
              this.state.order.orderStatus == "DELIVERY_EN_ROUTE" ? (
                <Col>
                  <CompleteTask id={this.state.order.id} refreshOrders={this.props.refreshOrders} />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ paddingTop: "3vh" }}>
          <Col span={24}>
            <GoboltTimelineInfoContent order={this.state.order} />
          </Col>
        </Row>

        <Row style={{ paddingTop: "3vh" }}>
          <Col span={24}>
            <ScanInfoContent
              recipientAddress={this.state.order.recipientAddress}
              originAddress={this.state.order.originAddress}
              scanEvents={this.state.order.scanEvents}
              otherScans={this.state.order.otherScans}
              packagesReceivedAt={this.state.order.packagesReceivedAt}
              packageCount={this.state.order.packageCount}
              refreshOrdersFromEdit={this.props.refreshOrdersFromEdit}
              id={this.props.order.id}
            />
          </Col>
        </Row>
        {this.state.order.timeChangeEvents != null && this.state.order.timeChangeEvents.length > 0 && (
          <Row style={{ paddingTop: "3vh" }}>
            <Col span={24}>
              <TimeChangeEventInfoContent timeChangeEvents={this.state.order.timeChangeEvents} />
            </Col>
          </Row>
        )}
        <PackagesDetailsModal
          isViewPackageDetailsVisible={this.state.isViewPackageDetailsVisible}
          toggleViewPackageDetails={this.toggleViewPackageDetails}
          packages={this.state.order?.packages}
        />
      </div>
    );
  }
}

export default OrderListDetails;
