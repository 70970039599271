// Authentication and Authorization Actions
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_LOAD_ERROR = "USER_LOAD_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Error Actions
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Feature Flag Actions
export const SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG = "SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG";
