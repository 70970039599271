import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Spin } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { loginUser } from "../actions/authActions";
import { setHeavyParcelConsolidationV1FeatureFlag } from "../actions/featureFlagActions";
import boltLogo from "../assets/bolt-logo-dark.svg";
import "./Login.css";
const FormItem = Form.Item;

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFinish = async () => {
    const { email, password } = this.state;
    // Attempt to login
    await this.props.loginUser(email.trim(), password);
    await this.props.setHeavyParcelConsolidationV1FeatureFlag();
  };

  render() {
    if (this.props.isAuthenticated === true) {
      if (this.props.location.state != null) {
        return <Redirect to={this.props.location.state.from} />;
      } else {
        return <Redirect to={{ pathname: "/dashboard" }} />;
      }
    }

    return (
      <div className={"login-container"}>
        <Row style={{ minHeight: "85vh" }}>
          <Col
            sm={{ span: 16, offset: 4 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
            style={{ marginTop: "15vh", minHeight: "55vh" }}
          >
            <Row type={"flex"} justify={"center"} align={"middle"} className={"logo-row"}>
              <Card style={{ width: "100vh" }}>
                <Row type={"flex"} justify={"center"} align={"middle"}>
                  <Col>
                    <img
                      src={boltLogo}
                      alt="logo"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        marginBottom: "20px",
                      }}
                    />
                  </Col>
                </Row>
                <Spin spinning={this.props.isAuthenticating}>
                  <Form name={"login"} onFinish={this.onFinish}>
                    <FormItem>
                      <Input
                        className={"login-input"}
                        prefix={<MailOutlined />}
                        size="large"
                        name="email"
                        placeholder="Email"
                        onChange={this.onChange}
                      />
                    </FormItem>
                    <FormItem>
                      <Input.Password
                        className={"login-input"}
                        prefix={<LockOutlined />}
                        size="large"
                        type="password"
                        name="password"
                        onChange={this.onChange}
                        placeholder="Password"
                      />
                    </FormItem>
                    <Row type={"flex"} justify={"center"}>
                      <FormItem>
                        <Button
                          className={"login-button"}
                          type={"primary"}
                          shape={"round"}
                          size={"large"}
                          htmlType="submit"
                        >
                          Log In
                        </Button>
                      </FormItem>
                    </Row>
                    <Row type={"flex"} justify={"center"} align={"middle"}>
                      <span>
                        Don't have an account? <Link to="/register"> Register here</Link>
                      </span>
                    </Row>
                  </Form>
                </Spin>
              </Card>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

Login.propTypes = {
  setHeavyParcelConsolidationV1FeatureFlag: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isAuthenticating: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
});

export default connect(mapStateToProps, {
  loginUser,
  setHeavyParcelConsolidationV1FeatureFlag,
})(Login);
