import { SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG } from "../actions/types";

const initialState = {
  heavyParcelConsolidationV1: Boolean(localStorage.getItem(SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG)),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG:
      localStorage.setItem(SET_HEAVY_PARCEL_CONSOLIDATION_V1_FLAG, action.type.value);
      return {
        ...state,
        heavyParcelConsolidationV1: Boolean(action.payload.value),
      };
    default:
      return state;
  }
}
