import { Modal, Table } from "antd";
import React from "react";

const PackagesDetailsModal = ({ isViewPackageDetailsVisible, toggleViewPackageDetails, packages = [] }) => {
  const packagesColumns = [
    {
      title: "ID",
      dataIndex: "trackingNumber",
      key: "trackingNumber",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "Ref Number",
      dataIndex: "refNumber",
      key: "refNumber",
    },
  ];

  const packagesDataSource = packages.map((item) => {
    return {
      key: item.trackingNumber,
      trackingNumber: item.trackingNumber,
      weight: item.weightOptions ? [item.weightOptions.weight, item.weightOptions.unit].join(" ") : "-",
      length: item.sizeOptions ? [item.sizeOptions.length, item.sizeOptions.unit].join(" ") : "-",
      width: item.sizeOptions ? [item.sizeOptions.width, item.sizeOptions.unit].join(" ") : "-",
      height: item.sizeOptions ? [item.sizeOptions.height, item.sizeOptions.unit].join(" ") : "-",
      refNumber: item.refNumber || "-",
    };
  });
  return (
    <Modal
      onOk={() => {
        toggleViewPackageDetails(false);
      }}
      visible={isViewPackageDetailsVisible}
      closable={false}
      width="50%"
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Table dataSource={packagesDataSource} size="large" pagination={false} columns={packagesColumns} />
    </Modal>
  );
};

export default PackagesDetailsModal;
